<template>
  <div class="pt-10">
    <v-row>
      <v-col>
        <div>
          <div class="title-float">
            {{ $t("title.anexos") }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-text>
            <v-expansion-panels
              focusable
              multiple>

              <v-expansion-panel
                v-for="fornecedor in apuracao.fornecedores"
                :key="fornecedor.id">

                <v-expansion-panel-header>
                  {{ `${$tc('label.fornecedor')} ${fornecedor.codFornecedor}` }}
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <apuracao-fornecedor-arquivo
                    :somente-leitura="somenteLeitura"
                    :fornecedor="fornecedor">
                  </apuracao-fornecedor-arquivo>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ApuracaoFornecedorArquivo from './ApuracaoFornecedorArquivo';

export default {
  name: 'ArquivosFornecedorTabela',
  components: {
    ApuracaoFornecedorArquivo,
  },
  data() {
    return {
      headers: [
        {
          text: this.$tc('label.anexos'), value: 'anexos', class: 'nowrap', color: 'blue',
        },
      ],
    };
  },
  props: {
    apuracao: {},
    somenteLeitura: Boolean,
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
