<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('label.informe_justificativa') }}</span>
      </v-card-title>
      <v-form lazy-validation ref="form">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-textarea
                name="justificativa_textarea"
                required
                :rows="1"
                auto-grow
                autofocus
                maxlength="150"
                v-model.trim="justificativa"
                :rules="[rules.required]">
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text
          color="secundary"
          @click="closeModalJustificativa">{{ $t('label.cancelar') }}
        </v-btn>
        <v-btn text
          color="primary"
          @click="confirmarCancelamento()">{{ $t('label.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DialogApuracaoContratoCancelamento',
  props: {
    confirmarCancelarApuracao: Function,
  },
  data() {
    return {
      dialog: false,
      justificativa: '',
      apuracao: {},
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    open(item) {
      this.dialog = true;
      this.justificativa = '';
      this.apuracao = item;
    },
    close() {
      this.dialog = false;
    },
    confirmarCancelamento() {
      this.confirmarCancelarApuracao(this.apuracao, this.justificativa);
      this.dialog = false;
    },
    closeModalJustificativa() {
      this.dialog = false;
    },
  },
};
</script>
