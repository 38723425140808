<template>
  <div>
    <v-card-title>
      <div v-if="!somenteLeitura">
        <span>{{ $tc('label.anexo', 1)}}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0" style="margin-top: -4px !important;" icon v-on="on" @click="fazerUpload">
            <v-icon>attach_file</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('title.upload_evidencia') }}</span>
        </v-tooltip>
      </div>
    </v-card-title>
      <div class="painel-anexo">
        <v-row>
          <list-files
            @ListFiles__arquivos="(files) => $emit('FormAnexoArquivo__qtde', files.length)"
            ref="listFiles"
            :habilitar-exclusao="habilitarExclusao"
            :uri="uri">
          </list-files>
        </v-row>
      </div>
      <upload-file-form
        ref="uploadArquivo"
        :id="!!fornecedor && fornecedor.id ? fornecedor.id : 200"
        :id-entidade="!!fornecedor && fornecedor.id ? fornecedor.id : 200"
        :title="$t('label.upload_anexo')"
        :url="uriUpload"
        :auto-process-queue="false"
        @UPLOADFILE_SENDING="enviandoArquivo"
        @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
        @UploadArquivo__success="uploadSucesso">
        <v-text-field
          v-model="descricaoArquivo"
          :rules="[rules.required]"
          :counter="150"
          maxlength="150"
          :label="`${$t('label.descricao')}*`"
          required
        ></v-text-field>
      </upload-file-form>
    </div>
  </template>
<script>
import { mapGetters } from 'vuex';
import Confirm from '@/produto/shared-components/vuetify/dialog/Confirm';
import basePath from '../../../common/functions/api-resource';
import ListFiles from '../../../shared-components/files/ListFiles';
import UploadFileForm from '../../../shared-components/upload/UploadFileForm';

export default {
  name: 'ApuracaoFornecedorArquivo',
  components: {
    UploadFileForm,
    ListFiles,
    Confirm,
  },
  props: {
    fornecedor: Object,
    somenteLeitura: Boolean,
    habilitarExclusao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descricaoArquivo: null,
      exibeArquivos: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      planejamentoAcaoCadastroResource: this.$api.planejamentoAcaoCadastro(this.$resource),
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    uri() {
      const urlUpload = basePath('job', 'arquivo/apuracao-fornecedor');
      return `${urlUpload}/apuracao/${this.fornecedor.idApuracao}/fornecedor/${this.fornecedor.id}`;
    },
    uriUpload() {
      const urlUpload = basePath('job', 'arquivo/apuracao-fornecedor');
      return `${urlUpload}/apuracao/${this.fornecedor.idApuracao}/fornecedor/${this.fornecedor.id}/upload`;
    },
    hasRole() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ACAO_CRUD').length;
    },
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
    },
    fazerUpload() {
      this.$refs.uploadArquivo.open();
    },
  },
};
</script>
<style scoped>
.anexo-desabilitado {
padding: 12px 0 0 12px;
font-size: 16px !important;
color: red;
}
.painel-anexo .container.mx-0 {
max-width: 97% !important;
}
</style>
