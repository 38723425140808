<template>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card>
      <v-card-title style="word-break: break-word; width: 97%;">
        {{ $tc('title.acao_externa', 1) }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          id="dialog-acao-externa-codigo"
          index="1"
          ref="textacaoExternaCodigo"
          name="Acao_externa_codigo"
          v-model="codAcaoExterna"
          rows="1"
          class="mt-4"
          :label="`${$t('label.id')} *`"
          :counter="64"
          maxlength="64">
        </v-text-field>
        <v-container fluid px-0 py-0>
          <v-row v-if="codAcaoExternaParcial">
            <v-col cols="12" >
              <v-data-table
                :headers="headers"
                :items="acaoFornecedorExterna"
                item-key="acoes"
                :options.sync="pagination"
                :server-items-length="totalPage"
                class="elevation-0"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                }">
                <template v-slot:[`item.periodo`]="{ item }">
                  {{formatarPeriodo(item)}}
                </template>
                 <template v-slot:[`item.codAcaoExternaParcial`]="{ item }">
                  <v-tooltip v-if="item.codAcaoExternaParcial" bottom>
                    <template v-if="item.codAcaoExternaParcial.length>10"  v-slot:activator="{ on }">
                      <v-title v-on="on">{{ item.codAcaoExternaParcial.substring(0, 10) + '...' }}</v-title>
                    </template>
                    <template v-else v-slot:activator="{}">
                      <v-title>{{ item.codAcaoExternaParcial }}</v-title>
                    </template>
                    <span>{{ item.codAcaoExternaParcial }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="close()">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="salvar(itemSelecionado, codAcaoExterna)" color="primary" :disabled="!podeSalvar">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import moment from 'moment';
import {
  buscarAcaoExternaFornecedor,
} from '../../../common/resources/apuracao/apuracao-contrato';
import { forceLoading, forceHideLoading } from '../../../common/functions/loading';

export default {
  props: {
    salvarIdAcaoFornecedorAlterado: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      pagination: {},
      acaoFornecedorExterna: {},
      idApuracao: null,
      codAcaoExterna: null,
      codAcaoExternaParcial: null,
      totalPage: 10,
      itemSelecionado: {},
      headers: [
        {
          text: this.$tc('label.periodo', 2), value: 'periodo', class: 'nowrap', cellClass: 'nowrap', sortable: false,
        },
        { text: this.$tc('label.acao_externa_id', 2), value: 'codAcaoExternaParcial', sortable: false },
      ],
    };
  },
  computed: {
    podeSalvar() {
      return !!this.codAcaoExterna;
    },
  },
  methods: {
    open(item) {
      this.itemSelecionado = item;
      this.dialog = true;
      if (item) {
        forceLoading();
        this.buscarAcaoExternaFornecedor(item);
      }
    },
    close() {
      this.item = {};
      this.dialog = false;
    },
    buscarAcaoExternaFornecedor(item) {
      const idApuracao = Number(item.idApuracao);
      this.idApuracao = idApuracao;
      if (item.codFornecedorAcao) {
        this.codAcaoExterna = item.codFornecedorAcao;
      } else {
        this.codAcaoExterna = null;
      }
      buscarAcaoExternaFornecedor({ idApuracao }, this.$resource)
        .then((response) => {
          if (response.body) {
            this.acaoFornecedorExterna = response.body;
            forceHideLoading();
          }
          this.codAcaoExternaParcial = this.acaoFornecedorExterna[0].codAcaoExternaParcial;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    formatarPeriodo(item) {
      const dataInicioFormatada = moment(item.dataInicio, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
      const dataFimFormatada = moment(item.dataFim, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
      if (dataInicioFormatada !== dataFimFormatada) {
        return `${dataInicioFormatada} a ${dataFimFormatada}`;
      }
      return dataInicioFormatada;
    },
    salvar(item, codFornecedorAcao) {
      this.salvarIdAcaoFornecedorAlterado(item, codFornecedorAcao);
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
