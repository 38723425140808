<template>
  <v-card style="width: 95%;" class="ml-8 mt-10">
    <v-tabs
      align-with-title
      v-model="tabSelecionada"
      :disabled="desabilitaTabs"
      @change="clickTab">

      <v-tab href="#tab-mensal" :disabled="desabilitaTabs"  background-color="cyan">
        {{ `${$tc('label.mensal', 2)} (${totalizadoresPeriodos.mensal})` }}
      </v-tab>
      <v-tab href="#tab-trimestral" :disabled="desabilitaTabs">
        {{ `${$tc('label.trimestral', 2)} (${totalizadoresPeriodos.trimestral})`}}
      </v-tab>
      <v-tab href="#tab-semestral" :disabled="desabilitaTabs">
        {{ `${$tc('label.semestral', 2)} (${totalizadoresPeriodos.semestral})` }}
      </v-tab>
      <v-tab href="#tab-anual" :disabled="desabilitaTabs">
        {{ `${$tc('label.anual', 2)} (${totalizadoresPeriodos.anual})` }}
      </v-tab>

      <v-tabs-items v-model="tabSelecionada">
        <v-tab-item id="tab-mensal">
          <apuracao-contrato-tab-mensal
            ref="tab-mensal"
            :visao="visao"
            :key="visao"
            :apuracoes="apuracoes"
            :total-page="totalPage"
            :lista-tipos-uso="listaTiposUso"
            :selecionar-item="selecionarItem"
            :filtros="filtros"
            :montar-data-inicio-fim-edicao-valores="montarDataInicioFimEdicaoValores"
            :finalizar-apuracao="finalizarApuracao"
            @ApuracaoContratoTabMensal__AplicaFiltros="aplicarFiltrosPaginacao"
            v-show="tabSelecionada === 'tab-mensal'"/>
        </v-tab-item>

        <v-tab-item id="tab-trimestral">
          <apuracao-contrato-tab-trimestral
            ref="tab-trimestral"
            :visao="visao"
            :key="visao"
            :apuracoes="apuracoes"
            :total-page="totalPage"
            :lista-tipos-uso="listaTiposUso"
            :selecionar-item="selecionarItem"
            :filtros="filtros"
            :montar-data-inicio-fim-edicao-valores="montarDataInicioFimEdicaoValores"
            :finalizar-apuracao="finalizarApuracao"
            @ApuracaoContratoTabTrimestral__AplicaFiltros="aplicarFiltrosPaginacao"
            v-show="tabSelecionada === 'tab-trimestral'"/>
        </v-tab-item>

        <v-tab-item id="tab-semestral">
          <apuracao-contrato-tab-semestral
            ref="tab-semestral"
            :visao="visao"
            :key="visao"
            :apuracoes="apuracoes"
            :total-page="totalPage"
            :lista-tipos-uso="listaTiposUso"
            :selecionar-item="selecionarItem"
            :filtros="filtros"
            :montar-data-inicio-fim-edicao-valores="montarDataInicioFimEdicaoValores"
            :finalizar-apuracao="finalizarApuracao"
            @ApuracaoContratoTabSemestral__AplicaFiltros="aplicarFiltrosPaginacao"
            v-show="tabSelecionada === 'tab-semestral'"/>
        </v-tab-item>

        <v-tab-item id="tab-anual">
          <apuracao-contrato-tab-anual
            ref="tab-anual"
            :visao="visao"
            :key="visao"
            :apuracoes="apuracoes"
            :total-page="totalPage"
            :lista-tipos-uso="listaTiposUso"
            :selecionar-item="selecionarItem"
            :verificaRetornaDatasValidas="verificaRetornaDatasValidas"
            :filtros="filtros"
            :montar-data-inicio-fim-edicao-valores="montarDataInicioFimEdicaoValores"
            :finalizar-apuracao="finalizarApuracao"
            @ApuracaoContratoTabAnual__AplicaFiltros="aplicarFiltrosPaginacao"
            v-show="tabSelecionada === 'tab-anual'"/>
        </v-tab-item>

      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>

import ApuracaoContratoTabMensal from './ApuracaoContratoTabMensal';
import ApuracaoContratoTabTrimestral from './ApuracaoContratoTabTrimestral';
import ApuracaoContratoTabSemestral from './ApuracaoContratoTabSemestral';
import ApuracaoContratoTabAnual from './ApuracaoContratoTabAnual';

export default {
  name: 'ApuracaoContratoTabs',
  components: {
    ApuracaoContratoTabMensal,
    ApuracaoContratoTabTrimestral,
    ApuracaoContratoTabSemestral,
    ApuracaoContratoTabAnual,
  },
  props: {
    totalizadoresPeriodos: {
      type: Object,
    },
    verificaRetornaDatasValidas: {
      type: Function,
    },
    montarDataInicioFimEdicaoValores: {
      type: Function,
    },
    filtros: {
      type: Object,
    },
    apuracoes: {
      type: Array,
      default: () => ([]),
    },
    totalPage: {
      type: Number,
      default: 0,
    },
    desabilitaTabs: {
      type: Boolean,
      default: true,
    },
    listaTiposUso: {
      type: Array,
      default: () => ([]),
    },
    selecionarItem: Function,
    finalizarApuracao: Function,
  },
  data() {
    return {
      itemsPerPage: 5,
      tabSelecionada: null,
      visao: '',
      count: 0,
      pag: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    aplicarFiltrosPaginacao(pagination) {
      this.$emit('ApuracaoContratoFiltrosObrigatorios__AplicaFiltros', pagination, this.tabSelecionada.split('-')[1], false);
    },
    carregaTabComDados(tab) {
      this.tabSelecionada = tab;
    },
    clickTab() {
      this.$emit('ApuracaoContratoFiltrosObrigatorios__AplicaFiltros', this.pag, this.tabSelecionada.split('-')[1], true);
      if (this.apuracoes.length) {
        this.resetarPaginationTabs(this.tabSelecionada);
      }
    },
    resetarPaginationTabs(tab) {
      setTimeout(() => this.$refs[tab].resetarPagination(), 2E2);
    },
  },

  mounted() {
  },
};
</script>
<style lang="scss">
  .my-custom-dialog {
    align-self: flex-start;
  }
</style>
