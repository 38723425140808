<template>
  <div>
    <v-data-table
      :headers="headerFornecedor"
      :items="apuracao.fornecedores"
      class="elevation-0 my-3"
      :single-select="true"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      hide-default-footer
      disable-pagination
      :items-per-page="-1">
      <template v-slot:[`item.exportar`]="{ item }">
        <tbody>
          <td class="justify-center px-0" style="width: 1%; text-align: center;">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click="exportarNotas(item)">
                  <v-icon>get_app</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.exportar') }}</span>
            </v-tooltip>
          </td>
        </tbody>
        </template>
        <template v-slot:[`item.icon`]="{ item }">
          <v-tooltip bottom v-if="podeFinalizarFornecedor(item)">
            <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="orange darken-1"
                dark
                v-bind="attrs"
                v-on="on"
            >
                report_problem
            </v-icon>
            </template>
            <span>{{ $t('message.periodo_total_ou_parte_apurado') }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="statusAprovado(item) || item.indFornecedorFinalizado">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="deep-orange accent-4"
                dark
                v-bind="attrs"
                v-on="on"
              >
                info
              </v-icon>
            </template>
            <span>{{ $t('label.periodo_apurado') }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.total`]="{ item }">
        {{ getMoney(item.total) }}
        </template>

        <template v-slot:[`item.cnpj`]="{ item }">
        {{ item.cnpj }}
        </template>

        <template class="justify-center" v-slot:[`item.devolucoes`]="{ item }">
        {{ formatarValor(item.devolucoes) }}
        </template>

        <template v-slot:[`item.icms`]="{ item }">
        {{ formatarValor(item.icms) }}
        </template>

        <template v-slot:[`item.ipi`]="{ item }">
        {{ formatarValor(item.ipi) }}
        </template>

        <template v-slot:[`item.pisCofins`]="{ item }">
        {{ formatarValor(item.pisCofins) }}
        </template>

        <template v-slot:[`item.valorBase`]="{ item }">
          <input-money
            :id="'id-valor-base-novo-nd' + item.id"
            v-if="item.indAlteradoManualmente"
            v-model="item.valorBase"
            :hint="getMoney(item.valorBaseOriginal)"
            :disabled="true"
            :persistent-hint="true"
            class="v-text-field"
          ></input-money>
          <div v-else class="mt-2">
            {{ getMoney(item.valorBase) }}
          </div>
        </template>

        <template v-slot:[`item.valorNdGerado`]="{ item }">
          <input-money
            :id="'id-valor-novo-nd' + item.id"
            v-if="item.indAlteradoManualmente"
            v-model="item.valorNdPrevisto"
            :hint="getMoney(item.valorNdPrevistoOriginal)"
            :disabled="true"
            :persistent-hint="true"
            class="v-text-field"
          ></input-money>
          <div v-else class="mt-2">
            {{ item.valorNdGerado ? getMoney(item.valorNdGerado) : getMoney(item.valorNdPrevisto)}}
          </div>
        </template>
    </v-data-table>
    <v-row align="end" justify="end" class="pb-6" v-if="acessoRecebimentoEspecial">
      <v-col cols="auto">
        <v-btn
          density="default"
          @click="abrirModalRecebimentoParcial()"
          color="primary">
          {{ apuracaoAprovada ? $t('label.detalhes_alteracao') : $t('label.alterar_valor_por_cnpj') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="exibeModalRecebimentoParcial"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition">
      <apuracao-contrato-modal-recebimento-parcial
        ref="modalRecebimentoParcial"
        :apuracao="apuracao"
        :somente-leitura="apuracaoAprovada"
        :dta-inicio="dtaInicio"
        :dta-fim="dtaFim"
        :finalizar-apuracao="finalizarApuracao"
        @RecarregarApuracao="recarregarApuracao"
        @FecharModalRecebimentoParcial="fecharModalRecebimentoParcial">
      </apuracao-contrato-modal-recebimento-parcial>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import InputMoney from '@/produto/shared-components/inputs/InputMoney';
import exportacao from '../../common/functions/exportacao';
import { getMoney, getPercent } from '../../common/functions/helpers';
import { formatlastDayOfTheMonth } from '../../common/functions/date-utils';
import ApuracaoContratoModalRecebimentoParcial from './modals/ApuracaoContratoModalRecebimentoParcial';

export default {
  name: 'ApuracaoContratoTabelaFornecedor',
  components: {
    InputMoney,
    ApuracaoContratoModalRecebimentoParcial,
  },
  props: {
    apuracao: Object,
    dtaInicio: String,
    dtaFim: String,
    finalizarApuracao: Function,
  },
  data() {
    return {
      headerFornecedor: [
        { text: '', value: 'exportar', sortable: false },
        { text: '', value: 'icon', sortable: false },
        {
          text: this.$tc('label.cod_fornecedor'), value: 'codFornecedor', class: 'nowrap', cellClass: 'align-right',
        },
        { text: this.$tc('label.razao_social'), value: 'razaoSocial' },
        {
          text: this.$tc('label.cnpj'), value: 'cnpj', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.categoria'), value: 'categoria', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_de_entrada'), value: 'total', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.devolucao', 2), value: 'devolucoes', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.icms'), value: 'icms', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.ipi'), value: 'ipi', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.pis_cofins'), value: 'pisCofins', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_base'), value: 'valorBase', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_nd'), value: 'valorNdGerado', cellClass: 'nowrap',
        },
      ],
      exibeModalRecebimentoParcial: false,
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    acessoRecebimentoEspecial() {
      return this.getAllRoles
        .filter((el) => el.indexOf('ALTERAR_VALOR_POR_CNPJ') !== -1).length > 0 && this.metaNaoAplica;
    },
    metaNaoAplica() {
      return this.apuracao.template === 'META_N/A';
    },
    apuracaoAprovada() {
      return this.apuracao.statusApuracao === 'APROVADO';
    },
  },
  watch: {
  },
  methods: {
    getPercent,
    getMoney,
    formatarValor(valor) {
      if (valor === 0) {
        return '-';
      }
      return this.getMoney(valor) || '-';
    },
    statusAprovado(item) {
      return item.statusApuracao === 'APROVADO';
    },
    podeFinalizarFornecedor(item) {
      return !this.statusAprovado(item) && !item.indFornecedorFinalizado && !item.possuiRecebimentoParcial;
    },
    exportarNotas(item) {
      if (!item.possuiNotas) {
        this.$toast(this.$t('message.sem_dados_notas_fiscais_apuracao'));
      }
      const params = {
        id_acao: Number(item.idAcao),
        id_apuracao: Number(item.idApuracao),
        id_fornecedor: item.idFornecedor,
        status: item.statusApuracao,
      };
      if (item.statusApuracao !== 'APROVADO') {
        params.nota_nao_finalizada = true;
      }
      if (this.dtaInicio && this.dtaFim) {
        params.dta_inicio_apuracao = moment(this.dtaInicio, 'YYYY-MM').format('YYYY-MM-DD');
        params.dta_fim_apuracao = formatlastDayOfTheMonth(moment(this.dtaFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
      }
      if (item.calculoBonificacao === 'SELLOUT') {
        exportacao.exportar(null, 'notas_sellout_memoria_apuracao_acao_contrato', this, params);
      } else {
        exportacao.exportar(null, 'notas_sellin_memoria_apuracao_contrato', this, params);
      }
    },
    abrirModalRecebimentoParcial() {
      this.exibeModalRecebimentoParcial = true;
    },
    fecharModalRecebimentoParcial() {
      this.exibeModalRecebimentoParcial = false;
    },
    recarregarApuracao() {
      this.$emit('RecarregarApuracao', this.apuracao);
    },
  },

  mounted() {
  },
};
</script>
  <style lang="scss">
    .my-custom-dialog {
      align-self: flex-start;
    }
  </style>
